import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d528a088 = () => interopDefault(import('../views/Home.vue' /* webpackChunkName: "" */))
const _34d26fc1 = () => interopDefault(import('../views/dashboard/DashboardAboutTeacher.vue' /* webpackChunkName: "" */))
const _4c8fb080 = () => interopDefault(import('../views/About.vue' /* webpackChunkName: "" */))
const _223f368b = () => interopDefault(import('../views/AccountNewPassword.vue' /* webpackChunkName: "" */))
const _8d9294d6 = () => interopDefault(import('../views/AccountRecovery.vue' /* webpackChunkName: "" */))
const _59b409ed = () => interopDefault(import('../views/ActionLink.vue' /* webpackChunkName: "" */))
const _181b62f8 = () => interopDefault(import('../views/ServiceOffer.vue' /* webpackChunkName: "" */))
const _ffa7e426 = () => interopDefault(import('../views/Avatars.vue' /* webpackChunkName: "" */))
const _6f7417b6 = () => interopDefault(import('../views/HomeTeacher.vue' /* webpackChunkName: "" */))
const _f958edc4 = () => interopDefault(import('../views/Help.vue' /* webpackChunkName: "" */))
const _50a5a647 = () => interopDefault(import('../views/ContinueRegister.vue' /* webpackChunkName: "" */))
const _2b6fa313 = () => interopDefault(import('../views/CookiePolicy.vue' /* webpackChunkName: "" */))
const _62b096e8 = () => interopDefault(import('../views/dashboard/Dashboard.vue' /* webpackChunkName: "" */))
const _20656c0b = () => interopDefault(import('../views/dashboard/DashboardHome.vue' /* webpackChunkName: "" */))
const _71dd80f6 = () => interopDefault(import('../views/dashboard/loaders/ProfileLoader.vue' /* webpackChunkName: "" */))
const _16df26aa = () => interopDefault(import('../views/Documents.vue' /* webpackChunkName: "" */))
const _04716a5f = () => interopDefault(import('../views/DocumentsSplit.vue' /* webpackChunkName: "" */))
const _3f70bcdd = () => interopDefault(import('../views/DocumentsTutoreSmall.vue' /* webpackChunkName: "" */))
const _315dbd04 = () => interopDefault(import('../views/DocumentsTutorePoland.vue' /* webpackChunkName: "" */))
const _517caabf = () => interopDefault(import('../views/EnrollChooseProfile.vue' /* webpackChunkName: "" */))
const _30fe6566 = () => interopDefault(import('../views/EnrollCompleteData.vue' /* webpackChunkName: "" */))
const _7d89f418 = () => interopDefault(import('../views/EnrollOTP.vue' /* webpackChunkName: "" */))
const _ac83407a = () => interopDefault(import('../views/EnrollPhoneNumberExist.vue' /* webpackChunkName: "" */))
const _4b026d7e = () => interopDefault(import('../views/EnrollVerifyProfileOtp.vue' /* webpackChunkName: "" */))
const _bdb46530 = () => interopDefault(import('../views/EnrollWithoutAccount.vue' /* webpackChunkName: "" */))
const _5a9e4940 = () => interopDefault(import('../views/RegisterCC.vue' /* webpackChunkName: "" */))
const _0c23f588 = () => interopDefault(import('../views/firstLoginTeacher/FirstLogin.vue' /* webpackChunkName: "" */))
const _4eb38d0b = () => interopDefault(import('../views/Holiday.vue' /* webpackChunkName: "" */))
const _3dd94964 = () => interopDefault(import('../views/HolidayBreak.vue' /* webpackChunkName: "" */))
const _d3dca306 = () => interopDefault(import('../views/LeadOfferNew.vue' /* webpackChunkName: "" */))
const _12dfeaba = () => interopDefault(import('../views/firstLoginTeacher/LessonsAvailability.vue' /* webpackChunkName: "" */))
const _35ef2e5c = () => interopDefault(import('../views/Login.vue' /* webpackChunkName: "" */))
const _f33a35d4 = () => interopDefault(import('../views/LoginTeacher.vue' /* webpackChunkName: "" */))
const _4c0bd02a = () => interopDefault(import('../views/MaturaOffer.vue' /* webpackChunkName: "" */))
const _1f32b911 = () => interopDefault(import('../views/firstLoginTeacher/Media.vue' /* webpackChunkName: "" */))
const _2e6fa0f4 = () => interopDefault(import('../views/Messenger.vue' /* webpackChunkName: "" */))
const _382c6c98 = () => interopDefault(import('../views/NoProfilesForService.vue' /* webpackChunkName: "" */))
const _6d341d4a = () => interopDefault(import('../views/Notifications.vue' /* webpackChunkName: "" */))
const _620c14c2 = () => interopDefault(import('../views/onboarding/Onboarding.vue' /* webpackChunkName: "" */))
const _aaa86610 = () => interopDefault(import('../views/Password.vue' /* webpackChunkName: "" */))
const _e329f3c6 = () => interopDefault(import('../views/PaymentForm.vue' /* webpackChunkName: "" */))
const _0cd3885c = () => interopDefault(import('../views/PaymentOverview.vue' /* webpackChunkName: "" */))
const _69ca024d = () => interopDefault(import('../views/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _d0ed2e56 = () => interopDefault(import('../views/ProfileAdd.vue' /* webpackChunkName: "" */))
const _729c1c1e = () => interopDefault(import('../views/ProfileAddSuccess.vue' /* webpackChunkName: "" */))
const _5a450272 = () => interopDefault(import('../views/Profiles.vue' /* webpackChunkName: "" */))
const _04e43cfa = () => interopDefault(import('../views/Referral.vue' /* webpackChunkName: "" */))
const _36c4d456 = () => interopDefault(import('../views/dashboard/DashboardLessonDetail.vue' /* webpackChunkName: "" */))
const _dc402266 = () => interopDefault(import('../views/dashboard/DashboardReferralSummary.vue' /* webpackChunkName: "" */))
const _2802997a = () => interopDefault(import('../views/SchoolsMap.vue' /* webpackChunkName: "" */))
const _ae8e6b00 = () => interopDefault(import('../views/Settings.vue' /* webpackChunkName: "" */))
const _a57be03c = () => interopDefault(import('../views/Statement.vue' /* webpackChunkName: "" */))
const _564146aa = () => interopDefault(import('../views/dashboard/DashboardLessonMaterial.vue' /* webpackChunkName: "" */))
const _4216518e = () => interopDefault(import('../pages/testing.vue' /* webpackChunkName: "pages/testing" */))
const _aa44354a = () => interopDefault(import('../views/dashboard/DashboardTypography.vue' /* webpackChunkName: "" */))
const _235163f1 = () => interopDefault(import('../views/WelcomeScreenTeacher.vue' /* webpackChunkName: "" */))
const _1050a7cf = () => interopDefault(import('../views/Offer.vue' /* webpackChunkName: "" */))
const _5223ecbe = () => interopDefault(import('../views/dashboard/DashboardCoursesArchived.vue' /* webpackChunkName: "" */))
const _0db4d3f8 = () => interopDefault(import('../views/dashboard/DashboardBillings.vue' /* webpackChunkName: "" */))
const _3c2cbb3e = () => interopDefault(import('../views/dashboard/DashboardAvatars.vue' /* webpackChunkName: "" */))
const _41aaeb34 = () => interopDefault(import('../views/dashboard/DashboardDiploma.vue' /* webpackChunkName: "" */))
const _193336be = () => interopDefault(import('../views/dashboard/DashboardTeacherEvaluations.vue' /* webpackChunkName: "" */))
const _6432dbe9 = () => interopDefault(import('../views/dashboard/DashboardTeacherGroup.vue' /* webpackChunkName: "" */))
const _01ab0cc2 = () => interopDefault(import('../views/dashboard/DashboardTeacherIndividual.vue' /* webpackChunkName: "" */))
const _fcabbac6 = () => interopDefault(import('../views/Settlements.vue' /* webpackChunkName: "" */))
const _85fedf50 = () => interopDefault(import('../views/dashboard/DashboardTeacherTrialLesson.vue' /* webpackChunkName: "" */))
const _62b12b20 = () => interopDefault(import('../views/EnrollSuccess.vue' /* webpackChunkName: "" */))
const _9a9eff32 = () => interopDefault(import('../views/export/AskAboutServiceForm.vue' /* webpackChunkName: "" */))
const _2fdebdbf = () => interopDefault(import('../views/firstLoginTeacher/BasicForm.vue' /* webpackChunkName: "" */))
const _7108a0fa = () => interopDefault(import('../views/firstLoginTeacher/CertificatesForm.vue' /* webpackChunkName: "" */))
const _f1c3b68e = () => interopDefault(import('../views/firstLoginTeacher/EducationForm.vue' /* webpackChunkName: "" */))
const _41751ff1 = () => interopDefault(import('../views/firstLoginTeacher/ExperienceForm.vue' /* webpackChunkName: "" */))
const _295c6e93 = () => interopDefault(import('../views/firstLoginTeacher/Summary.vue' /* webpackChunkName: "" */))
const _239763ec = () => interopDefault(import('../views/oneByOne/IndividualProceed.vue' /* webpackChunkName: "" */))
const _5e41d3bb = () => interopDefault(import('../views/oneByOne/IndividualEnrollSuccess.vue' /* webpackChunkName: "" */))
const _21b0c422 = () => interopDefault(import('../views/firstLoginTeacher/AvailabilityForm.vue' /* webpackChunkName: "" */))
const _f83154e8 = () => interopDefault(import('../views/firstLoginTeacher/LessonsForm.vue' /* webpackChunkName: "" */))
const _6d12b05f = () => interopDefault(import('../views/firstLoginTeacher/Photo.vue' /* webpackChunkName: "" */))
const _21dd8bb0 = () => interopDefault(import('../views/firstLoginTeacher/Video.vue' /* webpackChunkName: "" */))
const _a6c0dc94 = () => interopDefault(import('../views/Pins/ConfirmData.vue' /* webpackChunkName: "" */))
const _64313348 = () => interopDefault(import('../views/Pins/InsertPin.vue' /* webpackChunkName: "" */))
const _555e75d9 = () => interopDefault(import('../views/Pins/NewPin.vue' /* webpackChunkName: "" */))
const _0ec6da6f = () => interopDefault(import('../views/ProfilesTestRenewal.vue' /* webpackChunkName: "" */))
const _5f695fc2 = () => interopDefault(import('../views/Pins/SetPinUser.vue' /* webpackChunkName: "" */))
const _6aa092cc = () => interopDefault(import('../views/Pins/WelcomeScreen.vue' /* webpackChunkName: "" */))
const _129f4f57 = () => interopDefault(import('../views/RegisterCCOld.vue' /* webpackChunkName: "" */))
const _8ef1f584 = () => interopDefault(import('../views/RegisterFail.vue' /* webpackChunkName: "" */))
const _f516dcda = () => interopDefault(import('../views/RegisterSuccess.vue' /* webpackChunkName: "" */))
const _33fa15f2 = () => interopDefault(import('../views/RegisterTeacher.vue' /* webpackChunkName: "" */))
const _99ddea96 = () => interopDefault(import('../views/teacher/TeacherPayments.vue' /* webpackChunkName: "" */))
const _46a49cb2 = () => interopDefault(import('../views/teacher/TeacherData.vue' /* webpackChunkName: "" */))
const _709dba0a = () => interopDefault(import('../views/teacher/TeacherProfileEdit.vue' /* webpackChunkName: "" */))
const _3ca3d9cc = () => interopDefault(import('../views/EnrollContinuesSuccess.vue' /* webpackChunkName: "" */))
const _4ba686e0 = () => interopDefault(import('../views/EnrollPaymentFailed.vue' /* webpackChunkName: "" */))
const _0d83e440 = () => interopDefault(import('../views/EnrollPaymentSuccess.vue' /* webpackChunkName: "" */))
const _7d96d7e0 = () => interopDefault(import('../views/firstLoginTeacher/SummaryResult.vue' /* webpackChunkName: "" */))
const _18c90619 = () => interopDefault(import('../views/firstLoginTeacher/VideoRecord.vue' /* webpackChunkName: "" */))
const _7dab7e94 = () => interopDefault(import('../views/PaymentResult.vue' /* webpackChunkName: "" */))
const _7428f82f = () => interopDefault(import('../views/RegisterTeacherResult.vue' /* webpackChunkName: "" */))
const _29d2313b = () => interopDefault(import('../views/dashboard/oneByOne/ReservationCancelledLesson.vue' /* webpackChunkName: "" */))
const _4379a8e8 = () => interopDefault(import('../views/dashboard/DashboardEvaluation.vue' /* webpackChunkName: "" */))
const _29a7d91b = () => interopDefault(import('../views/dashboard/loaders/GroupLoader.vue' /* webpackChunkName: "" */))
const _6fab8760 = () => interopDefault(import('../views/dashboard/DashboardTeacherIndividualDetail.vue' /* webpackChunkName: "" */))
const _17d923e8 = () => interopDefault(import('../views/dashboard/DashboardPaymentsSummaryClient.vue' /* webpackChunkName: "" */))
const _4d4cbec2 = () => interopDefault(import('../views/ProfileSettings.vue' /* webpackChunkName: "" */))
const _34daa4b0 = () => interopDefault(import('../views/firstLoginTeacher/Candidate.vue' /* webpackChunkName: "" */))
const _94da4678 = () => interopDefault(import('../views/oneByOne/IndividualBuy.vue' /* webpackChunkName: "" */))
const _75df0abd = () => interopDefault(import('../views/EnrollAfterTrial.vue' /* webpackChunkName: "" */))
const _91057912 = () => interopDefault(import('../views/oneByOne/IndividualPaymentFinish.vue' /* webpackChunkName: "" */))
const _4d1cd858 = () => interopDefault(import('../views/oneByOne/IndividualTrial.vue' /* webpackChunkName: "" */))
const _622e13be = () => interopDefault(import('../views/PaymentIndividualNew.vue' /* webpackChunkName: "" */))
const _76799c16 = () => interopDefault(import('../views/Pins/DeletePin.vue' /* webpackChunkName: "" */))
const _7669efd7 = () => interopDefault(import('../views/Pins/EditPin.vue' /* webpackChunkName: "" */))
const _39abb652 = () => interopDefault(import('../views/Pins/SetPin.vue' /* webpackChunkName: "" */))
const _4aa90b39 = () => interopDefault(import('../views/BuyPassiveServiceFinished.vue' /* webpackChunkName: "" */))
const _61b3fc96 = () => interopDefault(import('../views/dashboard/loaders/CourseLoader.vue' /* webpackChunkName: "" */))
const _d62d38de = () => interopDefault(import('../views/dashboard/DashboardQuizStart.vue' /* webpackChunkName: "" */))
const _cd745438 = () => interopDefault(import('../views/dashboard/DashboardLessonRate.vue' /* webpackChunkName: "" */))
const _6878d17d = () => interopDefault(import('../views/dashboard/DashboardDetail.vue' /* webpackChunkName: "" */))
const _c6b0d670 = () => interopDefault(import('../views/dashboard/DashboardVideoLessonDetail.vue' /* webpackChunkName: "" */))
const _5bd91559 = () => interopDefault(import('../views/EnrollContinues.vue' /* webpackChunkName: "" */))
const _59d8f4eb = () => interopDefault(import('../views/PaymentServiceGroupTurbo.vue' /* webpackChunkName: "" */))
const _7ad743ba = () => interopDefault(import('../views/PaymentServiceGroup.vue' /* webpackChunkName: "" */))
const _502694fc = () => interopDefault(import('../views/EnrollPin.vue' /* webpackChunkName: "" */))
const _58670e88 = () => interopDefault(import('../views/ServiceLeadOffer.vue' /* webpackChunkName: "" */))
const _af6d952c = () => interopDefault(import('../views/StrapiShortUrls.vue' /* webpackChunkName: "" */))
const _b52d0330 = () => interopDefault(import('../views/PaymentPage.vue' /* webpackChunkName: "" */))
const _ca328a5e = () => interopDefault(import('../views/ReferralInvited.vue' /* webpackChunkName: "" */))
const _1d27fea7 = () => interopDefault(import('../views/BuyPassiveService.vue' /* webpackChunkName: "" */))
const _4c32290a = () => interopDefault(import('../views/ServiceTeacher.vue' /* webpackChunkName: "" */))
const _1ddadd40 = () => interopDefault(import('../views/PaymentOverviewDetails.vue' /* webpackChunkName: "" */))
const _ee5286ee = () => interopDefault(import('../views/ConfirmReservation.vue' /* webpackChunkName: "" */))
const _0cbc9a7a = () => interopDefault(import('../views/Enroll.vue' /* webpackChunkName: "" */))
const _2314fd00 = () => interopDefault(import('../views/Register.vue' /* webpackChunkName: "" */))
const _cec99988 = () => interopDefault(import('../views/EnrollIndividual.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _d528a088,
    props: {},
    name: "Home___en"
  }, {
    path: "/pl",
    component: _d528a088,
    props: {},
    name: "Home___pl"
  }, {
    path: "/en/about-teacher",
    component: _34d26fc1,
    props: {},
    name: "DashboardMyProfileHomeCourseIdAboutTeacher___en"
  }, {
    path: "/en/about-us",
    component: _4c8fb080,
    props: {},
    name: "About___en"
  }, {
    path: "/en/account-new-password",
    component: _223f368b,
    props: {},
    name: "AccountNewPassword___en"
  }, {
    path: "/en/account-recovery",
    component: _8d9294d6,
    props: {},
    name: "AccountRecovery___en"
  }, {
    path: "/en/action-link",
    component: _59b409ed,
    props: {},
    name: "ActionLink___en"
  }, {
    path: "/en/angielski-dla-dziecka-lets-talk-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":38},
    name: "ServiceOffer32___en"
  }, {
    path: "/en/angielski-dla-dziecka-lets-talk-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":34},
    name: "ServiceOffer25___en"
  }, {
    path: "/en/avatar",
    component: _ffa7e426,
    props: {},
    name: "Avatars___en"
  }, {
    path: "/en/career",
    component: _6f7417b6,
    props: {},
    name: "HomeTeacher___en"
  }, {
    path: "/en/contact",
    component: _f958edc4,
    props: {},
    name: "Help___en"
  }, {
    path: "/en/continue-register",
    component: _50a5a647,
    props: {},
    name: "ContinueRegister___en"
  }, {
    path: "/en/cookie-policy",
    component: _2b6fa313,
    props: {},
    name: "CookiePolicy___en"
  }, {
    path: "/en/dashboard",
    component: _62b096e8,
    props: {"access":["tutor","client","student"]},
    name: "Dashboard___en"
  }, {
    path: "/en/dashboard",
    component: _20656c0b,
    props: {},
    name: "DashboardHome___en"
  }, {
    path: "/en/dashboard",
    component: _71dd80f6,
    props: {},
    name: "DashboardProfileLoaderId___en"
  }, {
    path: "/en/documents",
    component: _16df26aa,
    props: {},
    name: "Documents___en"
  }, {
    path: "/en/documents-company",
    component: _04716a5f,
    props: {},
    name: "DocumentsSplit___en"
  }, {
    path: "/en/documents-tutore",
    component: _3f70bcdd,
    props: {},
    name: "DocumentsTutoreSmall___en"
  }, {
    path: "/en/documents-tutore-poland",
    component: _315dbd04,
    props: {},
    name: "DocumentsTutorePoland___en"
  }, {
    path: "/en/enroll-choose-profile",
    component: _517caabf,
    props: {},
    name: "EnrollChooseProfile___en"
  }, {
    path: "/en/enroll-complete-data",
    component: _30fe6566,
    props: {},
    name: "EnrollCompleteData___en"
  }, {
    path: "/en/enroll-otp",
    component: _7d89f418,
    props: {},
    name: "EnrollOTP___en"
  }, {
    path: "/en/enroll-phone-number-exist",
    component: _ac83407a,
    props: {},
    name: "EnrollPhoneNumberExist___en"
  }, {
    path: "/en/enroll-verify-profile-otp",
    component: _4b026d7e,
    props: {},
    name: "EnrollVerifyProfileOtp___en"
  }, {
    path: "/en/enroll-without-account",
    component: _bdb46530,
    props: {},
    name: "EnrollWithoutAccount___en"
  }, {
    path: "/en/enter",
    component: _5a9e4940,
    props: {},
    name: "RegisterCC___en"
  }, {
    path: "/en/first-login",
    component: _0c23f588,
    props: {},
    name: "FirstLogin___en"
  }, {
    path: "/en/grafika-komputerowa-online-instagrafik-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":37},
    name: "ServiceOffer31___en"
  }, {
    path: "/en/grafika-komputerowa-online-instagrafik-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":33},
    name: "ServiceOffer24___en"
  }, {
    path: "/en/holiday",
    component: _4eb38d0b,
    props: {},
    name: "Holiday___en"
  }, {
    path: "/en/holiday-break",
    component: _3dd94964,
    props: {},
    name: "HolidayBreak___en"
  }, {
    path: "/en/lead-offer",
    component: _d3dca306,
    props: {},
    name: "LeadOffer___en"
  }, {
    path: "/en/lessons-availability",
    component: _12dfeaba,
    props: {},
    name: "LessonsAvailability___en"
  }, {
    path: "/en/logiczna-przygoda-minecraft-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":36},
    name: "ServiceOffer30___en"
  }, {
    path: "/en/logiczna-przygoda-minecraft-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":32},
    name: "ServiceOffer23___en"
  }, {
    path: "/en/login",
    component: _35ef2e5c,
    props: {},
    name: "Login___en"
  }, {
    path: "/en/login-teacher",
    component: _f33a35d4,
    props: {},
    name: "LoginTeacher___en"
  }, {
    path: "/en/matematyka-dla-dzieci-minecraft-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":39},
    name: "ServiceOffer33___en"
  }, {
    path: "/en/matematyka-dla-dzieci-minecraft-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":35},
    name: "ServiceOffer26___en"
  }, {
    path: "/en/matura",
    component: _4c0bd02a,
    props: {},
    name: "MaturaOffer___en"
  }, {
    path: "/en/media",
    component: _1f32b911,
    props: {},
    name: "Media___en"
  }, {
    path: "/en/messenger",
    component: _2e6fa0f4,
    props: {},
    name: "Messenger___en"
  }, {
    path: "/en/nauka-angielskiego-online",
    component: _181b62f8,
    props: {"type":"group","id":26},
    name: "ServiceOffer19___en"
  }, {
    path: "/en/no-profiles-for-services",
    component: _382c6c98,
    props: {},
    name: "NoProfilesForService___en"
  }, {
    path: "/en/notifications",
    component: _6d341d4a,
    props: {},
    name: "Notifications___en"
  }, {
    path: "/en/onboarding",
    component: _620c14c2,
    props: {},
    name: "Onboarding___en"
  }, {
    path: "/en/password",
    component: _aaa86610,
    props: {},
    name: "Password___en"
  }, {
    path: "/en/payment-form",
    component: _e329f3c6,
    props: {},
    name: "PaymentForm___en"
  }, {
    path: "/en/payment-overview",
    component: _0cd3885c,
    props: {},
    name: "PaymentOverview___en"
  }, {
    path: "/en/privacy-policy",
    component: _69ca024d,
    props: {},
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/profile-add",
    component: _d0ed2e56,
    props: {},
    name: "ProfileAdd___en"
  }, {
    path: "/en/profile-add-success",
    component: _729c1c1e,
    props: {},
    name: "ProfileAddSuccess___en"
  }, {
    path: "/en/profiles",
    component: _5a450272,
    props: {},
    name: "Profiles___en"
  }, {
    path: "/en/programpolecajacy",
    component: _04e43cfa,
    props: {},
    name: "Referral___en"
  }, {
    path: "/en/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileLessonDetailRate___en"
  }, {
    path: "/en/referral-summary",
    component: _dc402266,
    props: {},
    name: "DashboardReferralSummary___en"
  }, {
    path: "/en/schools-map",
    component: _2802997a,
    props: {},
    name: "SchoolsMap___en"
  }, {
    path: "/en/settings",
    component: _ae8e6b00,
    props: {},
    name: "Settings___en"
  }, {
    path: "/en/statement",
    component: _a57be03c,
    props: {},
    name: "Statement___en"
  }, {
    path: "/en/ta",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial2___en"
  }, {
    path: "/en/testing",
    component: _4216518e,
    name: "testing___en"
  }, {
    path: "/en/typography",
    component: _aa44354a,
    props: {},
    name: "DashboardTypography___en"
  }, {
    path: "/en/welcome-screen-teacher",
    component: _235163f1,
    props: {},
    name: "WelcomeScreenTeacher___en"
  }, {
    path: "/pl/about-teacher",
    component: _34d26fc1,
    props: {},
    name: "DashboardMyProfileHomeCourseIdAboutTeacher___pl"
  }, {
    path: "/pl/account-new-password",
    component: _223f368b,
    props: {},
    name: "AccountNewPassword___pl"
  }, {
    path: "/pl/account-recovery",
    component: _8d9294d6,
    props: {},
    name: "AccountRecovery___pl"
  }, {
    path: "/pl/action-link",
    component: _59b409ed,
    props: {},
    name: "ActionLink___pl"
  }, {
    path: "/pl/angielski-dla-dziecka-lets-talk-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":38},
    name: "ServiceOffer32___pl"
  }, {
    path: "/pl/angielski-dla-dziecka-lets-talk-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":34},
    name: "ServiceOffer25___pl"
  }, {
    path: "/pl/avatar",
    component: _ffa7e426,
    props: {},
    name: "Avatars___pl"
  }, {
    path: "/pl/continue-register",
    component: _50a5a647,
    props: {},
    name: "ContinueRegister___pl"
  }, {
    path: "/pl/cookie-policy",
    component: _2b6fa313,
    props: {},
    name: "CookiePolicy___pl"
  }, {
    path: "/pl/dashboard",
    component: _62b096e8,
    props: {"access":["tutor","client","student"]},
    name: "Dashboard___pl"
  }, {
    path: "/pl/dashboard",
    component: _20656c0b,
    props: {},
    name: "DashboardHome___pl"
  }, {
    path: "/pl/dashboard",
    component: _71dd80f6,
    props: {},
    name: "DashboardProfileLoaderId___pl"
  }, {
    path: "/pl/dokumenty",
    component: _16df26aa,
    props: {},
    name: "Documents___pl"
  }, {
    path: "/pl/dokumenty-spolka",
    component: _04716a5f,
    props: {},
    name: "DocumentsSplit___pl"
  }, {
    path: "/pl/dokumenty-tutore",
    component: _3f70bcdd,
    props: {},
    name: "DocumentsTutoreSmall___pl"
  }, {
    path: "/pl/dokumenty-tutore-poland",
    component: _315dbd04,
    props: {},
    name: "DocumentsTutorePoland___pl"
  }, {
    path: "/pl/enroll-choose-profile",
    component: _517caabf,
    props: {},
    name: "EnrollChooseProfile___pl"
  }, {
    path: "/pl/enroll-complete-data",
    component: _30fe6566,
    props: {},
    name: "EnrollCompleteData___pl"
  }, {
    path: "/pl/enroll-otp",
    component: _7d89f418,
    props: {},
    name: "EnrollOTP___pl"
  }, {
    path: "/pl/enroll-phone-number-exist",
    component: _ac83407a,
    props: {},
    name: "EnrollPhoneNumberExist___pl"
  }, {
    path: "/pl/enroll-verify-profile-otp",
    component: _4b026d7e,
    props: {},
    name: "EnrollVerifyProfileOtp___pl"
  }, {
    path: "/pl/enroll-without-account",
    component: _bdb46530,
    props: {},
    name: "EnrollWithoutAccount___pl"
  }, {
    path: "/pl/enter",
    component: _5a9e4940,
    props: {},
    name: "RegisterCC___pl"
  }, {
    path: "/pl/first-login",
    component: _0c23f588,
    props: {},
    name: "FirstLogin___pl"
  }, {
    path: "/pl/grafika-komputerowa-online-instagrafik-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":37},
    name: "ServiceOffer31___pl"
  }, {
    path: "/pl/grafika-komputerowa-online-instagrafik-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":33},
    name: "ServiceOffer24___pl"
  }, {
    path: "/pl/holiday",
    component: _4eb38d0b,
    props: {},
    name: "Holiday___pl"
  }, {
    path: "/pl/holiday-break",
    component: _3dd94964,
    props: {},
    name: "HolidayBreak___pl"
  }, {
    path: "/pl/kariera",
    component: _6f7417b6,
    props: {},
    name: "HomeTeacher___pl"
  }, {
    path: "/pl/kontakt",
    component: _f958edc4,
    props: {},
    name: "Help___pl"
  }, {
    path: "/pl/lead-offer",
    component: _d3dca306,
    props: {},
    name: "LeadOffer___pl"
  }, {
    path: "/pl/lessons-availability",
    component: _12dfeaba,
    props: {},
    name: "LessonsAvailability___pl"
  }, {
    path: "/pl/logiczna-przygoda-minecraft-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":36},
    name: "ServiceOffer30___pl"
  }, {
    path: "/pl/logiczna-przygoda-minecraft-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":32},
    name: "ServiceOffer23___pl"
  }, {
    path: "/pl/login",
    component: _35ef2e5c,
    props: {},
    name: "Login___pl"
  }, {
    path: "/pl/login-teacher",
    component: _f33a35d4,
    props: {},
    name: "LoginTeacher___pl"
  }, {
    path: "/pl/matematyka-dla-dzieci-minecraft-polkolonie",
    component: _181b62f8,
    props: {"type":"group","id":39},
    name: "ServiceOffer33___pl"
  }, {
    path: "/pl/matematyka-dla-dzieci-minecraft-wakacje",
    component: _181b62f8,
    props: {"type":"group","id":35},
    name: "ServiceOffer26___pl"
  }, {
    path: "/pl/matura",
    component: _4c0bd02a,
    props: {},
    name: "MaturaOffer___pl"
  }, {
    path: "/pl/media",
    component: _1f32b911,
    props: {},
    name: "Media___pl"
  }, {
    path: "/pl/messenger",
    component: _2e6fa0f4,
    props: {},
    name: "Messenger___pl"
  }, {
    path: "/pl/nauka-angielskiego-online",
    component: _181b62f8,
    props: {"type":"group","id":26},
    name: "ServiceOffer19___pl"
  }, {
    path: "/pl/no-profiles-for-services",
    component: _382c6c98,
    props: {},
    name: "NoProfilesForService___pl"
  }, {
    path: "/pl/notifications",
    component: _6d341d4a,
    props: {},
    name: "Notifications___pl"
  }, {
    path: "/pl/o-nas",
    component: _4c8fb080,
    props: {},
    name: "About___pl"
  }, {
    path: "/pl/onboarding",
    component: _620c14c2,
    props: {},
    name: "Onboarding___pl"
  }, {
    path: "/pl/password",
    component: _aaa86610,
    props: {},
    name: "Password___pl"
  }, {
    path: "/pl/payment-form",
    component: _e329f3c6,
    props: {},
    name: "PaymentForm___pl"
  }, {
    path: "/pl/payment-overview",
    component: _0cd3885c,
    props: {},
    name: "PaymentOverview___pl"
  }, {
    path: "/pl/polityka-prywatnosci",
    component: _69ca024d,
    props: {},
    name: "PrivacyPolicy___pl"
  }, {
    path: "/pl/profile-add",
    component: _d0ed2e56,
    props: {},
    name: "ProfileAdd___pl"
  }, {
    path: "/pl/profile-add-success",
    component: _729c1c1e,
    props: {},
    name: "ProfileAddSuccess___pl"
  }, {
    path: "/pl/profiles",
    component: _5a450272,
    props: {},
    name: "Profiles___pl"
  }, {
    path: "/pl/programpolecajacy",
    component: _04e43cfa,
    props: {},
    name: "Referral___pl"
  }, {
    path: "/pl/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileLessonDetailRate___pl"
  }, {
    path: "/pl/referral-summary",
    component: _dc402266,
    props: {},
    name: "DashboardReferralSummary___pl"
  }, {
    path: "/pl/schools-map",
    component: _2802997a,
    props: {},
    name: "SchoolsMap___pl"
  }, {
    path: "/pl/settings",
    component: _ae8e6b00,
    props: {},
    name: "Settings___pl"
  }, {
    path: "/pl/statement",
    component: _a57be03c,
    props: {},
    name: "Statement___pl"
  }, {
    path: "/pl/ta",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial2___pl"
  }, {
    path: "/pl/testing",
    component: _4216518e,
    name: "testing___pl"
  }, {
    path: "/pl/typography",
    component: _aa44354a,
    props: {},
    name: "DashboardTypography___pl"
  }, {
    path: "/pl/welcome-screen-teacher",
    component: _235163f1,
    props: {},
    name: "WelcomeScreenTeacher___pl"
  }, {
    path: "/en/course/chess-lessons",
    component: _181b62f8,
    props: {"type":"group","id":16},
    name: "ServiceOffer17___en"
  }, {
    path: "/en/course/coding-with-minecraft",
    component: _181b62f8,
    props: {"type":"group","id":5},
    name: "ServiceOffer9___en"
  }, {
    path: "/en/course/digital-painting-for-kids",
    component: _181b62f8,
    props: {"type":"group","id":42},
    name: "ServiceOffer2___en"
  }, {
    path: "/en/course/drawing-lessons-for-adults",
    component: _181b62f8,
    props: {"type":"group","id":27},
    name: "ServiceOffer20___en"
  }, {
    path: "/en/course/drawing-lessons-for-kids",
    component: _181b62f8,
    props: {"type":"group","id":9},
    name: "ServiceOffer14___en"
  }, {
    path: "/en/course/eighth-grade-exam-english",
    component: _181b62f8,
    props: {"type":"group","id":15},
    name: "ServiceOffer4___en"
  }, {
    path: "/en/course/eighth-grade-exam-english-MAX",
    component: _181b62f8,
    props: {"type":"group","id":29},
    name: "ServiceOffer36___en"
  }, {
    path: "/en/course/eighth-grade-exam-maths",
    component: _181b62f8,
    props: {"type":"group","id":14},
    name: "ServiceOffer5___en"
  }, {
    path: "/en/course/eighth-grade-exam-maths-MAX",
    component: _181b62f8,
    props: {"type":"group","id":28},
    name: "ServiceOffer35___en"
  }, {
    path: "/en/course/eighth-grade-exam-polish",
    component: _181b62f8,
    props: {"type":"group","id":22},
    name: "ServiceOffer7___en"
  }, {
    path: "/en/course/eighth-grade-exam-polish-MAX",
    component: _181b62f8,
    props: {"type":"group","id":30},
    name: "ServiceOffer34___en"
  }, {
    path: "/en/course/english-for-kids",
    component: _181b62f8,
    props: {"type":"group","id":7},
    name: "ServiceOffer11___en"
  }, {
    path: "/en/course/english-for-kids-lets-talk",
    component: _181b62f8,
    props: {"type":"group","id":12},
    name: "ServiceOffer16___en"
  }, {
    path: "/en/course/graphic-design-classes-for-adults",
    component: _181b62f8,
    props: {"type":"group","id":24},
    name: "ServiceOffer18___en"
  }, {
    path: "/en/course/graphic-design-classes-for-kids",
    component: _181b62f8,
    props: {"type":"group","id":8},
    name: "ServiceOffer13___en"
  }, {
    path: "/en/course/guitar-lessons",
    component: _181b62f8,
    props: {"type":"group","id":4},
    name: "ServiceOffer3___en"
  }, {
    path: "/en/course/how-to-create-game-with-godot-engine",
    component: _181b62f8,
    props: {"type":"group","id":41},
    name: "ServiceOffer1___en"
  }, {
    path: "/en/course/making-music-for-kids-and-teens",
    component: _181b62f8,
    props: {"type":"group","id":31},
    name: "ServiceOffer21___en"
  }, {
    path: "/en/course/maths-for-kids-and-teens",
    component: _181b62f8,
    props: {"type":"group","id":6},
    name: "ServiceOffer10___en"
  }, {
    path: "/en/course/maths-with-minecraft-for-kids",
    component: _181b62f8,
    props: {"type":"group","id":11},
    name: "ServiceOffer15___en"
  }, {
    path: "/en/course/matura-exam-english-basic",
    component: _181b62f8,
    props: {"type":"group","id":54},
    name: "ServiceOffer6___en"
  }, {
    path: "/en/course/matura-exam-english-MAX",
    component: _181b62f8,
    props: {"type":"group","id":55},
    name: "ServiceOffer37___en"
  }, {
    path: "/en/course/matura-exam-maths-basic",
    component: _181b62f8,
    props: {"type":"group","id":59},
    name: "ServiceOffer12___en"
  }, {
    path: "/en/course/matura-exam-maths-MAX",
    component: _181b62f8,
    props: {"type":"group","id":56},
    name: "ServiceOffer38___en"
  }, {
    path: "/en/course/matura-exam-polish-basic",
    component: _181b62f8,
    props: {"type":"group","id":57},
    name: "ServiceOffer22___en"
  }, {
    path: "/en/course/matura-exam-polish-MAX",
    component: _181b62f8,
    props: {"type":"group","id":58},
    name: "ServiceOffer39___en"
  }, {
    path: "/en/courses/groups",
    component: _1050a7cf,
    props: {"type":"group"},
    name: "OfferGroup___en"
  }, {
    path: "/en/courses/individual",
    component: _1050a7cf,
    props: {"type":"individual"},
    name: "OfferIndividual___en"
  }, {
    path: "/en/dashboard/archived",
    component: _5223ecbe,
    props: {},
    name: "DashboardHomeCoursesArchived___en"
  }, {
    path: "/en/dashboard/billings",
    component: _0db4d3f8,
    props: {},
    name: "Billings___en"
  }, {
    path: "/en/dashboard/change-avatar",
    component: _3c2cbb3e,
    props: {},
    name: "DashboardAvatars___en"
  }, {
    path: "/en/dashboard/diploma",
    component: _41aaeb34,
    props: {},
    name: "DashboardDiploma___en"
  }, {
    path: "/en/dashboard/evaluations",
    component: _193336be,
    props: {},
    name: "DashboardTeacherEvaluations___en"
  }, {
    path: "/en/dashboard/group",
    component: _6432dbe9,
    props: {},
    name: "DashboardTeacherGroup___en"
  }, {
    path: "/en/dashboard/individual",
    component: _01ab0cc2,
    props: {},
    name: "DashboardTeacherIndividual___en"
  }, {
    path: "/en/dashboard/my-profile",
    component: _62b096e8,
    props: {},
    name: "DashboardMyProfile___en"
  }, {
    path: "/en/dashboard/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdLessonDetailRate___en"
  }, {
    path: "/en/dashboard/settlements",
    component: _fcabbac6,
    props: {},
    name: "Settlements___en"
  }, {
    path: "/en/dashboard/trial-lesson",
    component: _85fedf50,
    props: {},
    name: "DashboardTeacherTrialLesson___en"
  }, {
    path: "/en/enroll/success",
    component: _62b12b20,
    props: {},
    name: "EnrollSuccess___en"
  }, {
    path: "/en/export/ask-about-service-form",
    component: _9a9eff32,
    props: {},
    name: "AskAboutServiceForm___en"
  }, {
    path: "/en/first-login/basic",
    component: _2fdebdbf,
    props: {},
    name: "BasicForm___en"
  }, {
    path: "/en/first-login/certificates",
    component: _7108a0fa,
    props: {},
    name: "CertificatesForm___en"
  }, {
    path: "/en/first-login/education",
    component: _f1c3b68e,
    props: {},
    name: "EducationForm___en"
  }, {
    path: "/en/first-login/experience",
    component: _41751ff1,
    props: {},
    name: "ExperienceForm___en"
  }, {
    path: "/en/first-login/summary",
    component: _295c6e93,
    props: {},
    name: "Summary___en"
  }, {
    path: "/en/individual/proceed",
    component: _239763ec,
    props: {},
    name: "IndividualProceed___en"
  }, {
    path: "/en/individual/success",
    component: _5e41d3bb,
    props: {},
    name: "IndividualEnrollSuccess___en"
  }, {
    path: "/en/kurs/digital-painting-dla-dzieci",
    component: _181b62f8,
    props: {"type":"group","id":42},
    name: "ServiceOffer8___en"
  }, {
    path: "/en/kurs/fotografik-wirtualny-rysownik",
    component: _181b62f8,
    props: {"type":"group","id":21},
    name: "ServiceOffer28___en"
  }, {
    path: "/en/kurs/grafika-3d-architekt",
    component: _181b62f8,
    props: {"type":"group","id":43},
    name: "ServiceOffer29___en"
  }, {
    path: "/en/kurs/jak-stworzyc-gre-2D-w-Godot-Engine",
    component: _181b62f8,
    props: {"type":"group","id":41},
    name: "ServiceOffer41___en"
  }, {
    path: "/en/kurs/web-designer-marketignowiec",
    component: _181b62f8,
    props: {"type":"group","id":20},
    name: "ServiceOffer27___en"
  }, {
    path: "/en/lessons-availability/availability",
    component: _21b0c422,
    props: {},
    name: "AvailabilityForm___en"
  }, {
    path: "/en/lessons-availability/lessons",
    component: _f83154e8,
    props: {},
    name: "LessonsForm___en"
  }, {
    path: "/en/media/photo",
    component: _6d12b05f,
    props: {},
    name: "Photo___en"
  }, {
    path: "/en/media/video",
    component: _21dd8bb0,
    props: {},
    name: "Video___en"
  }, {
    path: "/en/profiles/confirm-data",
    component: _a6c0dc94,
    props: {},
    name: "ConfirmData___en"
  }, {
    path: "/en/profiles/insert-pin",
    component: _64313348,
    props: {},
    name: "InsertPin___en"
  }, {
    path: "/en/profiles/new-pin",
    component: _555e75d9,
    props: {},
    name: "NewPin___en"
  }, {
    path: "/en/profiles/renewal-test",
    component: _0ec6da6f,
    props: {},
    name: "ProfilesTestRenewal___en"
  }, {
    path: "/en/profiles/set-pin-user",
    component: _5f695fc2,
    props: {},
    name: "SetPinUser___en"
  }, {
    path: "/en/profiles/welcome-screen",
    component: _6aa092cc,
    props: {},
    name: "WelcomeScreen___en"
  }, {
    path: "/en/register/cc",
    component: _129f4f57,
    props: {},
    name: "RegisterCCOld___en"
  }, {
    path: "/en/register/fail",
    component: _8ef1f584,
    props: {},
    name: "RegisterFail___en"
  }, {
    path: "/en/register/success",
    component: _f516dcda,
    props: {},
    name: "RegisterSuccess___en"
  }, {
    path: "/en/register/teacher",
    component: _33fa15f2,
    props: {},
    name: "RegisterTeacher___en"
  }, {
    path: "/en/teacher/payments",
    component: _99ddea96,
    props: {},
    name: "TeacherPayments___en"
  }, {
    path: "/en/teacher/personal-data",
    component: _46a49cb2,
    props: {},
    name: "TeacherData___en"
  }, {
    path: "/en/teacher/profile-edit",
    component: _709dba0a,
    props: {},
    name: "TeacherProfileEdit___en"
  }, {
    path: "/pl/dashboard/archived",
    component: _5223ecbe,
    props: {},
    name: "DashboardHomeCoursesArchived___pl"
  }, {
    path: "/pl/dashboard/billings",
    component: _0db4d3f8,
    props: {},
    name: "Billings___pl"
  }, {
    path: "/pl/dashboard/change-avatar",
    component: _3c2cbb3e,
    props: {},
    name: "DashboardAvatars___pl"
  }, {
    path: "/pl/dashboard/diploma",
    component: _41aaeb34,
    props: {},
    name: "DashboardDiploma___pl"
  }, {
    path: "/pl/dashboard/evaluations",
    component: _193336be,
    props: {},
    name: "DashboardTeacherEvaluations___pl"
  }, {
    path: "/pl/dashboard/group",
    component: _6432dbe9,
    props: {},
    name: "DashboardTeacherGroup___pl"
  }, {
    path: "/pl/dashboard/individual",
    component: _01ab0cc2,
    props: {},
    name: "DashboardTeacherIndividual___pl"
  }, {
    path: "/pl/dashboard/my-profile",
    component: _62b096e8,
    props: {},
    name: "DashboardMyProfile___pl"
  }, {
    path: "/pl/dashboard/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdLessonDetailRate___pl"
  }, {
    path: "/pl/dashboard/settlements",
    component: _fcabbac6,
    props: {},
    name: "Settlements___pl"
  }, {
    path: "/pl/dashboard/trial-lesson",
    component: _85fedf50,
    props: {},
    name: "DashboardTeacherTrialLesson___pl"
  }, {
    path: "/pl/enroll/success",
    component: _62b12b20,
    props: {},
    name: "EnrollSuccess___pl"
  }, {
    path: "/pl/export/ask-about-service-form",
    component: _9a9eff32,
    props: {},
    name: "AskAboutServiceForm___pl"
  }, {
    path: "/pl/first-login/basic",
    component: _2fdebdbf,
    props: {},
    name: "BasicForm___pl"
  }, {
    path: "/pl/first-login/certificates",
    component: _7108a0fa,
    props: {},
    name: "CertificatesForm___pl"
  }, {
    path: "/pl/first-login/education",
    component: _f1c3b68e,
    props: {},
    name: "EducationForm___pl"
  }, {
    path: "/pl/first-login/experience",
    component: _41751ff1,
    props: {},
    name: "ExperienceForm___pl"
  }, {
    path: "/pl/first-login/summary",
    component: _295c6e93,
    props: {},
    name: "Summary___pl"
  }, {
    path: "/pl/individual/proceed",
    component: _239763ec,
    props: {},
    name: "IndividualProceed___pl"
  }, {
    path: "/pl/individual/success",
    component: _5e41d3bb,
    props: {},
    name: "IndividualEnrollSuccess___pl"
  }, {
    path: "/pl/kurs/angielski-dla-dziecka-lets-talk",
    component: _181b62f8,
    props: {"type":"group","id":12},
    name: "ServiceOffer16___pl"
  }, {
    path: "/pl/kurs/angielski-dla-dziecka-pearson",
    component: _181b62f8,
    props: {"type":"group","id":7},
    name: "ServiceOffer11___pl"
  }, {
    path: "/pl/kurs/digital-painting-dla-dzieci",
    component: _181b62f8,
    props: {"type":"group","id":42},
    name: "ServiceOffer2___pl"
  }, {
    path: "/pl/kurs/digital-painting-dla-dzieci",
    component: _181b62f8,
    props: {"type":"group","id":42},
    name: "ServiceOffer8___pl"
  }, {
    path: "/pl/kurs/egzamin-osmoklasisty-angielski-kurs-semestralny",
    component: _181b62f8,
    props: {"type":"group","id":15},
    name: "ServiceOffer4___pl"
  }, {
    path: "/pl/kurs/egzamin-osmoklasisty-angielski-MAX",
    component: _181b62f8,
    props: {"type":"group","id":29},
    name: "ServiceOffer36___pl"
  }, {
    path: "/pl/kurs/egzamin-osmoklasisty-matematyka-kurs-semestralny",
    component: _181b62f8,
    props: {"type":"group","id":14},
    name: "ServiceOffer5___pl"
  }, {
    path: "/pl/kurs/egzamin-osmoklasisty-matematyka-MAX",
    component: _181b62f8,
    props: {"type":"group","id":28},
    name: "ServiceOffer35___pl"
  }, {
    path: "/pl/kurs/egzamin-osmoklasisty-polski-kurs-semestralny",
    component: _181b62f8,
    props: {"type":"group","id":22},
    name: "ServiceOffer7___pl"
  }, {
    path: "/pl/kurs/egzamin-osmoklasisty-polski-MAX",
    component: _181b62f8,
    props: {"type":"group","id":30},
    name: "ServiceOffer34___pl"
  }, {
    path: "/pl/kurs/fotografik-wirtualny-rysownik",
    component: _181b62f8,
    props: {"type":"group","id":21},
    name: "ServiceOffer28___pl"
  }, {
    path: "/pl/kurs/grafika-3d-architekt",
    component: _181b62f8,
    props: {"type":"group","id":43},
    name: "ServiceOffer29___pl"
  }, {
    path: "/pl/kurs/grafika-komputerowa-online-dla-doroslych",
    component: _181b62f8,
    props: {"type":"group","id":24},
    name: "ServiceOffer18___pl"
  }, {
    path: "/pl/kurs/grafika-komputerowa-online-dla-dzieci",
    component: _181b62f8,
    props: {"type":"group","id":8},
    name: "ServiceOffer13___pl"
  }, {
    path: "/pl/kurs/jak-stworzyc-gre-2D-w-Godot-Engine",
    component: _181b62f8,
    props: {"type":"group","id":41},
    name: "ServiceOffer1___pl"
  }, {
    path: "/pl/kurs/jak-stworzyc-gre-2D-w-Godot-Engine",
    component: _181b62f8,
    props: {"type":"group","id":41},
    name: "ServiceOffer41___pl"
  }, {
    path: "/pl/kurs/kurs-rysunku-dla-doroslych",
    component: _181b62f8,
    props: {"type":"group","id":27},
    name: "ServiceOffer20___pl"
  }, {
    path: "/pl/kurs/kurs-rysunku-dla-dzieci",
    component: _181b62f8,
    props: {"type":"group","id":9},
    name: "ServiceOffer14___pl"
  }, {
    path: "/pl/kurs/matematyka-dla-dzieci-minecraft",
    component: _181b62f8,
    props: {"type":"group","id":11},
    name: "ServiceOffer15___pl"
  }, {
    path: "/pl/kurs/matematyka-dla-dzieci-online",
    component: _181b62f8,
    props: {"type":"group","id":6},
    name: "ServiceOffer10___pl"
  }, {
    path: "/pl/kurs/matura-angielski-MAX",
    component: _181b62f8,
    props: {"type":"group","id":55},
    name: "ServiceOffer37___pl"
  }, {
    path: "/pl/kurs/matura-angielski-podstawowy",
    component: _181b62f8,
    props: {"type":"group","id":54},
    name: "ServiceOffer6___pl"
  }, {
    path: "/pl/kurs/matura-matematyka-MAX",
    component: _181b62f8,
    props: {"type":"group","id":56},
    name: "ServiceOffer38___pl"
  }, {
    path: "/pl/kurs/matura-matematyka-podstawowy",
    component: _181b62f8,
    props: {"type":"group","id":59},
    name: "ServiceOffer12___pl"
  }, {
    path: "/pl/kurs/matura-polski-MAX",
    component: _181b62f8,
    props: {"type":"group","id":58},
    name: "ServiceOffer39___pl"
  }, {
    path: "/pl/kurs/matura-polski-podstawowy",
    component: _181b62f8,
    props: {"type":"group","id":57},
    name: "ServiceOffer22___pl"
  }, {
    path: "/pl/kurs/nauka-gry-na-gitarze-dla-dzieci",
    component: _181b62f8,
    props: {"type":"group","id":4},
    name: "ServiceOffer3___pl"
  }, {
    path: "/pl/kurs/nauka-gry-w-szachy-online",
    component: _181b62f8,
    props: {"type":"group","id":16},
    name: "ServiceOffer17___pl"
  }, {
    path: "/pl/kurs/programowanie-minecraft-dla-dzieci-mlodziezy",
    component: _181b62f8,
    props: {"type":"group","id":5},
    name: "ServiceOffer9___pl"
  }, {
    path: "/pl/kurs/tworzenie-muzyki-dla-dzieci-mlodziezy",
    component: _181b62f8,
    props: {"type":"group","id":31},
    name: "ServiceOffer21___pl"
  }, {
    path: "/pl/kurs/web-designer-marketignowiec",
    component: _181b62f8,
    props: {"type":"group","id":20},
    name: "ServiceOffer27___pl"
  }, {
    path: "/pl/kursy/grupowe",
    component: _1050a7cf,
    props: {"type":"group"},
    name: "OfferGroup___pl"
  }, {
    path: "/pl/kursy/indywidualne",
    component: _1050a7cf,
    props: {"type":"individual"},
    name: "OfferIndividual___pl"
  }, {
    path: "/pl/lessons-availability/availability",
    component: _21b0c422,
    props: {},
    name: "AvailabilityForm___pl"
  }, {
    path: "/pl/lessons-availability/lessons",
    component: _f83154e8,
    props: {},
    name: "LessonsForm___pl"
  }, {
    path: "/pl/media/photo",
    component: _6d12b05f,
    props: {},
    name: "Photo___pl"
  }, {
    path: "/pl/media/video",
    component: _21dd8bb0,
    props: {},
    name: "Video___pl"
  }, {
    path: "/pl/profiles/confirm-data",
    component: _a6c0dc94,
    props: {},
    name: "ConfirmData___pl"
  }, {
    path: "/pl/profiles/insert-pin",
    component: _64313348,
    props: {},
    name: "InsertPin___pl"
  }, {
    path: "/pl/profiles/new-pin",
    component: _555e75d9,
    props: {},
    name: "NewPin___pl"
  }, {
    path: "/pl/profiles/renewal-test",
    component: _0ec6da6f,
    props: {},
    name: "ProfilesTestRenewal___pl"
  }, {
    path: "/pl/profiles/set-pin-user",
    component: _5f695fc2,
    props: {},
    name: "SetPinUser___pl"
  }, {
    path: "/pl/profiles/welcome-screen",
    component: _6aa092cc,
    props: {},
    name: "WelcomeScreen___pl"
  }, {
    path: "/pl/register/cc",
    component: _129f4f57,
    props: {},
    name: "RegisterCCOld___pl"
  }, {
    path: "/pl/register/fail",
    component: _8ef1f584,
    props: {},
    name: "RegisterFail___pl"
  }, {
    path: "/pl/register/success",
    component: _f516dcda,
    props: {},
    name: "RegisterSuccess___pl"
  }, {
    path: "/pl/register/teacher",
    component: _33fa15f2,
    props: {},
    name: "RegisterTeacher___pl"
  }, {
    path: "/pl/teacher/payments",
    component: _99ddea96,
    props: {},
    name: "TeacherPayments___pl"
  }, {
    path: "/pl/teacher/personal-data",
    component: _46a49cb2,
    props: {},
    name: "TeacherData___pl"
  }, {
    path: "/pl/teacher/profile-edit",
    component: _709dba0a,
    props: {},
    name: "TeacherProfileEdit___pl"
  }, {
    path: "/en/enroll/continues/success",
    component: _3ca3d9cc,
    props: {},
    name: "EnrollContinuesSuccess___en"
  }, {
    path: "/en/enroll/payment/failed",
    component: _4ba686e0,
    props: {},
    name: "EnrollPaymentFailed___en"
  }, {
    path: "/en/enroll/payment/success",
    component: _0d83e440,
    props: {},
    name: "EnrollPaymentSuccess___en"
  }, {
    path: "/en/first-login/summary/result",
    component: _7d96d7e0,
    props: {},
    name: "SummaryResult___en"
  }, {
    path: "/en/media/video/record",
    component: _18c90619,
    props: {},
    name: "VideoRecord___en"
  }, {
    path: "/en/payment/individual/result",
    component: _7dab7e94,
    props: {},
    name: "PaymentResult___en"
  }, {
    path: "/en/register/teacher/success",
    component: _7428f82f,
    props: {},
    name: "RegisterTeacherResult___en"
  }, {
    path: "/pl/enroll/continues/success",
    component: _3ca3d9cc,
    props: {},
    name: "EnrollContinuesSuccess___pl"
  }, {
    path: "/pl/enroll/payment/failed",
    component: _4ba686e0,
    props: {},
    name: "EnrollPaymentFailed___pl"
  }, {
    path: "/pl/enroll/payment/success",
    component: _0d83e440,
    props: {},
    name: "EnrollPaymentSuccess___pl"
  }, {
    path: "/pl/first-login/summary/result",
    component: _7d96d7e0,
    props: {},
    name: "SummaryResult___pl"
  }, {
    path: "/pl/media/video/record",
    component: _18c90619,
    props: {},
    name: "VideoRecord___pl"
  }, {
    path: "/pl/payment/individual/result",
    component: _7dab7e94,
    props: {},
    name: "PaymentResult___pl"
  }, {
    path: "/pl/register/teacher/success",
    component: _7428f82f,
    props: {},
    name: "RegisterTeacherResult___pl"
  }, {
    path: "/en/dashboard/individual-new/lesson/reservation/lesson/:lessonId/profile/:profileId(\\d+)?",
    component: _29d2313b,
    props: {},
    name: "ReservationCancelledLesson___en"
  }, {
    path: "/pl/dashboard/individual-new/lesson/reservation/lesson/:lessonId/profile/:profileId(\\d+)?",
    component: _29d2313b,
    props: {},
    name: "ReservationCancelledLesson___pl"
  }, {
    path: "/en/dashboard/trial-lesson/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherTrialLesson2___en"
  }, {
    path: "/pl/dashboard/trial-lesson/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherTrialLesson2___pl"
  }, {
    path: "/en/dashboard/my-profile/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileHomeCourseIdLessonDetailRate___en"
  }, {
    path: "/pl/dashboard/my-profile/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileHomeCourseIdLessonDetailRate___pl"
  }, {
    path: "/en/dashboard/evaluation/:diplomaId",
    component: _4379a8e8,
    props: {},
    name: "DashboardEvaluation___en"
  }, {
    path: "/en/dashboard/group/:groupId(\\d+)?",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupDetail___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualNew___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualDetail___en"
  }, {
    path: "/en/dashboard/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdLessonDetail___en"
  }, {
    path: "/en/dashboard/payment-summary/:type",
    component: _17d923e8,
    props: {},
    name: "DashboardPaymentsSummaryClient2___en"
  }, {
    path: "/en/dashboard/profile-settings/:id",
    component: _4d4cbec2,
    props: {},
    name: "ProfileSettings___en"
  }, {
    path: "/en/dashboard/profile/:profileId",
    component: _71dd80f6,
    props: {},
    name: "DashboardProfileLoaderIdDashboardHomeStudent___en"
  }, {
    path: "/en/first-login/candidate/:id",
    component: _34daa4b0,
    props: {},
    name: "Candidate___en"
  }, {
    path: "/en/individual/buy/:courseId?",
    component: _94da4678,
    props: {},
    name: "IndividualBuy___en"
  }, {
    path: "/en/individual/enroll-after-trial/:courseId?",
    component: _75df0abd,
    props: {},
    name: "EnrollAfterTrial___en"
  }, {
    path: "/en/individual/finished/:statusType?",
    component: _91057912,
    props: {},
    name: "IndividualPaymentFinished___en"
  }, {
    path: "/en/individual/trial/:courseId?",
    component: _4d1cd858,
    props: {},
    name: "IndividualTrial___en"
  }, {
    path: "/en/offer/group/:id",
    component: _181b62f8,
    props: {},
    name: "ServiceOffer___en"
  }, {
    path: "/en/payment/individual-new/:token",
    component: _622e13be,
    props: {},
    name: "PaymentIndividualNew___en"
  }, {
    path: "/en/profiles/delete-pin/:id",
    component: _76799c16,
    props: {},
    name: "DeletePin___en"
  }, {
    path: "/en/profiles/edit-pin/:id",
    component: _7669efd7,
    props: {},
    name: "EditPin___en"
  }, {
    path: "/en/profiles/set-pin/:id",
    component: _39abb652,
    props: {},
    name: "SetPin___en"
  }, {
    path: "/en/purchase-content/finished/:statusType?",
    component: _4aa90b39,
    props: {},
    name: "BuyPassiveServiceFinished___en"
  }, {
    path: "/pl/dashboard/evaluation/:diplomaId",
    component: _4379a8e8,
    props: {},
    name: "DashboardEvaluation___pl"
  }, {
    path: "/pl/dashboard/group/:groupId(\\d+)?",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupDetail___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualNew___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualDetail___pl"
  }, {
    path: "/pl/dashboard/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdLessonDetail___pl"
  }, {
    path: "/pl/dashboard/payment-summary/:type",
    component: _17d923e8,
    props: {},
    name: "DashboardPaymentsSummaryClient2___pl"
  }, {
    path: "/pl/dashboard/profile-settings/:id",
    component: _4d4cbec2,
    props: {},
    name: "ProfileSettings___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId",
    component: _71dd80f6,
    props: {},
    name: "DashboardProfileLoaderIdDashboardHomeStudent___pl"
  }, {
    path: "/pl/first-login/candidate/:id",
    component: _34daa4b0,
    props: {},
    name: "Candidate___pl"
  }, {
    path: "/pl/individual/buy/:courseId?",
    component: _94da4678,
    props: {},
    name: "IndividualBuy___pl"
  }, {
    path: "/pl/individual/enroll-after-trial/:courseId?",
    component: _75df0abd,
    props: {},
    name: "EnrollAfterTrial___pl"
  }, {
    path: "/pl/individual/finished/:statusType?",
    component: _91057912,
    props: {},
    name: "IndividualPaymentFinished___pl"
  }, {
    path: "/pl/individual/trial/:courseId?",
    component: _4d1cd858,
    props: {},
    name: "IndividualTrial___pl"
  }, {
    path: "/pl/offer/group/:id",
    component: _181b62f8,
    props: {},
    name: "ServiceOffer___pl"
  }, {
    path: "/pl/payment/individual-new/:token",
    component: _622e13be,
    props: {},
    name: "PaymentIndividualNew___pl"
  }, {
    path: "/pl/profiles/delete-pin/:id",
    component: _76799c16,
    props: {},
    name: "DeletePin___pl"
  }, {
    path: "/pl/profiles/edit-pin/:id",
    component: _7669efd7,
    props: {},
    name: "EditPin___pl"
  }, {
    path: "/pl/profiles/set-pin/:id",
    component: _39abb652,
    props: {},
    name: "SetPin___pl"
  }, {
    path: "/pl/purchase-content/finished/:statusType?",
    component: _4aa90b39,
    props: {},
    name: "BuyPassiveServiceFinished___pl"
  }, {
    path: "/en/dashboard/group/:groupId(\\d+)?/calendar",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupCalendar___en"
  }, {
    path: "/en/dashboard/group/:groupId(\\d+)?/homework",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupHomework___en"
  }, {
    path: "/en/dashboard/group/:groupId(\\d+)?/media",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupMaterials___en"
  }, {
    path: "/en/dashboard/group/:groupId(\\d+)?/students",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupStudents___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/calendar",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualCalendarNew___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/homework",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualHomeworkNew___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/materials",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMaterialsNew___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/media",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMediaNew___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/student-profile",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualStudentNew___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?/calendar",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualCalendar___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?/homework",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualHomework___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?/materials",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMaterials___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?/media",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMedia___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/calendar",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdCalendar___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/course-detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdCourseDetail___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/course-program",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdCourseProgram___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseId___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/homework",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdHomework___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/materials",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdMaterials___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/progress",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdProgress___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/quizz",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdQuizz___en"
  }, {
    path: "/en/dashboard/profile/:profileId/diploma",
    component: _41aaeb34,
    props: {},
    name: "DashboardProfileLoaderIdDiploma___en"
  }, {
    path: "/pl/dashboard/group/:groupId(\\d+)?/calendar",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupCalendar___pl"
  }, {
    path: "/pl/dashboard/group/:groupId(\\d+)?/homework",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupHomework___pl"
  }, {
    path: "/pl/dashboard/group/:groupId(\\d+)?/media",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupMaterials___pl"
  }, {
    path: "/pl/dashboard/group/:groupId(\\d+)?/students",
    component: _29a7d91b,
    props: {},
    name: "DashboardTeacherGroupStudents___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/calendar",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualCalendarNew___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/homework",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualHomeworkNew___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/materials",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMaterialsNew___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/media",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMediaNew___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/student-profile",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualStudentNew___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?/calendar",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualCalendar___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?/homework",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualHomework___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?/materials",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMaterials___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?/media",
    component: _6fab8760,
    props: {},
    name: "DashboardTeacherIndividualMedia___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/calendar",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdCalendar___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/course-detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdCourseDetail___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/course-program",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdCourseProgram___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseId___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/homework",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdHomework___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/materials",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdMaterials___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/progress",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdProgress___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/quizz",
    component: _61b3fc96,
    props: {},
    name: "DashboardMyProfileHomeCourseIdQuizz___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/diploma",
    component: _41aaeb34,
    props: {},
    name: "DashboardProfileLoaderIdDiploma___pl"
  }, {
    path: "/en/dashboard/my-profile/:courseId(\\d+)?/detail/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileHomeCourseIdLessonDetail___en"
  }, {
    path: "/pl/dashboard/my-profile/:courseId(\\d+)?/detail/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileHomeCourseIdLessonDetail___pl"
  }, {
    path: "/en/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherGroupLessonDetail___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherIndividualLessonDetailNew___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherIndividualLessonDetail___en"
  }, {
    path: "/en/dashboard/my-profile/:courseId/quizz/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart3___en"
  }, {
    path: "/en/dashboard/profile/:profileId/evaluation/:diplomaId",
    component: _4379a8e8,
    props: {},
    name: "DashboardProfileLoaderIdEvaluation___en"
  }, {
    path: "/pl/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherGroupLessonDetail___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherIndividualLessonDetailNew___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardTeacherIndividualLessonDetail___pl"
  }, {
    path: "/pl/dashboard/my-profile/:courseId/quizz/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart3___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/evaluation/:diplomaId",
    component: _4379a8e8,
    props: {},
    name: "DashboardProfileLoaderIdEvaluation___pl"
  }, {
    path: "/en/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {"name":"DashboardLessonDetailRate"},
    name: "DashboardTeacherGroupLessonDetailRate___en"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _cd745438,
    props: {},
    name: "DashboardTeacherIndividualLessonDetailRateNew___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _cd745438,
    props: {},
    name: "DashboardTeacherIndividualLessonDetailRate___en"
  }, {
    path: "/pl/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {"name":"DashboardLessonDetailRate"},
    name: "DashboardTeacherGroupLessonDetailRate___pl"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _cd745438,
    props: {},
    name: "DashboardTeacherIndividualLessonDetailRateNew___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _cd745438,
    props: {},
    name: "DashboardTeacherIndividualLessonDetailRate___pl"
  }, {
    path: "/en/dashboard/individual-new/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial3New___en"
  }, {
    path: "/en/dashboard/individual/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial3___en"
  }, {
    path: "/pl/dashboard/individual-new/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial3New___pl"
  }, {
    path: "/pl/dashboard/individual/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial3___pl"
  }, {
    path: "/en/dashboard/lesson/:lessonId(\\d+)?/:type?",
    component: _36c4d456,
    props: {},
    name: "DashboardLessonDetail___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?",
    component: _6878d17d,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdDetail2___en"
  }, {
    path: "/en/dashboard/video/:courseId(\\d+)?/:type?",
    component: _c6b0d670,
    props: {},
    name: "DashboardVideoLessonDetail___en"
  }, {
    path: "/pl/dashboard/lesson/:lessonId(\\d+)?/:type?",
    component: _36c4d456,
    props: {},
    name: "DashboardLessonDetail___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?",
    component: _6878d17d,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdDetail2___pl"
  }, {
    path: "/pl/dashboard/video/:courseId(\\d+)?/:type?",
    component: _c6b0d670,
    props: {},
    name: "DashboardVideoLessonDetail___pl"
  }, {
    path: "/en/dashboard/lesson/:lessonId(\\d+)?/:type?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardLessonDetailRate___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/about-teacher",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdAboutTeacher___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/calendar",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdCalendar___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/course-detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdCourseDetail___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/course-program",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseProgram___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderHomeCourseIdDetail___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/homework",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdHomework___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/materials",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdMaterials___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/progress",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdProgress___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/quizz",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdQuizz___en"
  }, {
    path: "/pl/dashboard/lesson/:lessonId(\\d+)?/:type?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardLessonDetailRate___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/about-teacher",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdAboutTeacher___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/calendar",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdCalendar___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/course-detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdCourseDetail___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/course-program",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseProgram___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderHomeCourseIdDetail___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/homework",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdHomework___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/materials",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdMaterials___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/progress",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdProgress___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/quizz",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdQuizz___pl"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdLessonDetail___en"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId/quizz/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart2___en"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdLessonDetail___pl"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId/quizz/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart2___pl"
  }, {
    path: "/en/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdLessonDetailRate___en"
  }, {
    path: "/pl/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardProfileLoaderIdCourseIdLessonDetailRate___pl"
  }, {
    path: "/en/enroll/continues/:service/:profile/:isCc/:serviceConfigurationId?",
    component: _5bd91559,
    props: {},
    name: "EnrollContinues___en"
  }, {
    path: "/pl/enroll/continues/:service/:profile/:isCc/:serviceConfigurationId?",
    component: _5bd91559,
    props: {},
    name: "EnrollContinues___pl"
  }, {
    path: "/en/payment/group-turbo/:service/:course/:profile/:billingId?/:isOverdue?",
    component: _59d8f4eb,
    props: {},
    name: "PaymentServiceGroupTurbo___en"
  }, {
    path: "/en/payment/group/:service/:course/:profile/:billingId?/:isOverdue?",
    component: _7ad743ba,
    props: {},
    name: "PaymentServiceGroup___en"
  }, {
    path: "/pl/payment/group-turbo/:service/:course/:profile/:billingId?/:isOverdue?",
    component: _59d8f4eb,
    props: {},
    name: "PaymentServiceGroupTurbo___pl"
  }, {
    path: "/pl/payment/group/:service/:course/:profile/:billingId?/:isOverdue?",
    component: _7ad743ba,
    props: {},
    name: "PaymentServiceGroup___pl"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseId___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseId___en"
  }, {
    path: "/en/enroll-pin/:profileId",
    component: _502694fc,
    props: {},
    name: "EnrollPin___en"
  }, {
    path: "/en/enroll-without-account/:serviceId",
    component: _bdb46530,
    props: {},
    name: "EnrollWithoutAccountWithService___en"
  }, {
    path: "/en/lesson/:courseProgrammeId(\\d+)?",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial1___en"
  }, {
    path: "/en/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileLessonDetail___en"
  }, {
    path: "/en/lp/:serviceName",
    component: _58670e88,
    props: {},
    name: "ServiceLeadOffer___en"
  }, {
    path: "/en/m/:url",
    component: _af6d952c,
    props: {},
    name: "StrapiShortUrls___en"
  }, {
    path: "/en/payment-summary/:type",
    component: _17d923e8,
    props: {},
    name: "DashboardPaymentsSummaryClient1___en"
  }, {
    path: "/en/payment/:type",
    component: _b52d0330,
    props: {},
    name: "PaymentPage___en"
  }, {
    path: "/en/programpolecajacy/:promoCode",
    component: _ca328a5e,
    props: {},
    name: "ReferralInvited___en"
  }, {
    path: "/en/purchase-content/:contentId",
    component: _1d27fea7,
    props: {},
    name: "BuyPassiveService___en"
  }, {
    path: "/en/tutor/:tutorServiceId",
    component: _4c32290a,
    props: {},
    name: "ServiceTeacher___en"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseId___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?",
    component: _61b3fc96,
    props: {},
    name: "DashboardProfileLoaderIdCourseId___pl"
  }, {
    path: "/pl/enroll-pin/:profileId",
    component: _502694fc,
    props: {},
    name: "EnrollPin___pl"
  }, {
    path: "/pl/enroll-without-account/:serviceId",
    component: _bdb46530,
    props: {},
    name: "EnrollWithoutAccountWithService___pl"
  }, {
    path: "/pl/lesson/:courseProgrammeId(\\d+)?",
    component: _564146aa,
    props: {},
    name: "DashboardLessonMaterial1___pl"
  }, {
    path: "/pl/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardMyProfileLessonDetail___pl"
  }, {
    path: "/pl/lp/:serviceName",
    component: _58670e88,
    props: {},
    name: "ServiceLeadOffer___pl"
  }, {
    path: "/pl/m/:url",
    component: _af6d952c,
    props: {},
    name: "StrapiShortUrls___pl"
  }, {
    path: "/pl/payment-summary/:type",
    component: _17d923e8,
    props: {},
    name: "DashboardPaymentsSummaryClient1___pl"
  }, {
    path: "/pl/payment/:type",
    component: _b52d0330,
    props: {},
    name: "PaymentPage___pl"
  }, {
    path: "/pl/programpolecajacy/:promoCode",
    component: _ca328a5e,
    props: {},
    name: "ReferralInvited___pl"
  }, {
    path: "/pl/purchase-content/:contentId",
    component: _1d27fea7,
    props: {},
    name: "BuyPassiveService___pl"
  }, {
    path: "/pl/tutor/:tutorServiceId",
    component: _4c32290a,
    props: {},
    name: "ServiceTeacher___pl"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/about-teacher",
    component: _34d26fc1,
    props: {},
    name: "DashboardHomeCourseIdAboutTeacher___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/calendar",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdCalendar___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/course-detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdCourseDetail___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/course-program",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdCourseProgram___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdDetail___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/homework",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdHomework___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/materials",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdMaterials___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/progress",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdProgress___en"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/quizz",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdQuizz___en"
  }, {
    path: "/en/payment-overview/:orderId?/details",
    component: _1ddadd40,
    props: {},
    name: "PaymentOverviewDetails___en"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/about-teacher",
    component: _34d26fc1,
    props: {},
    name: "DashboardHomeCourseIdAboutTeacher___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/calendar",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdCalendar___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/course-detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdCourseDetail___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/course-program",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdCourseProgram___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/detail",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdDetail___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/homework",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdHomework___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/materials",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdMaterials___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/progress",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdProgress___pl"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/quizz",
    component: _61b3fc96,
    props: {},
    name: "DashboardHomeCourseIdQuizz___pl"
  }, {
    path: "/pl/payment-overview/:orderId?/details",
    component: _1ddadd40,
    props: {},
    name: "PaymentOverviewDetails___pl"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardHomeCourseIdLessonDetail___en"
  }, {
    path: "/en/dashboard/:courseId/detail/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart4___en"
  }, {
    path: "/en/dashboard/:courseId/quizz/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart1___en"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?",
    component: _36c4d456,
    props: {},
    name: "DashboardHomeCourseIdLessonDetail___pl"
  }, {
    path: "/pl/dashboard/:courseId/detail/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart4___pl"
  }, {
    path: "/pl/dashboard/:courseId/quizz/:lessonId",
    component: _d62d38de,
    props: {},
    name: "DashboardQuizStart1___pl"
  }, {
    path: "/en/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardHomeCourseIdLessonDetailRate___en"
  }, {
    path: "/pl/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate",
    component: _36c4d456,
    props: {},
    name: "DashboardHomeCourseIdLessonDetailRate___pl"
  }, {
    path: "/en/confirm-reservation/:reservationType/:tokenId",
    component: _ee5286ee,
    props: {"name":"ConfirmReservation"},
    name: "ConfirmReservation___en"
  }, {
    path: "/en/enroll/:type/:id",
    component: _0cbc9a7a,
    props: {},
    name: "Enroll1___en"
  }, {
    path: "/en/register/:type?/:courseId?",
    component: _2314fd00,
    props: {},
    name: "Register___en"
  }, {
    path: "/pl/confirm-reservation/:reservationType/:tokenId",
    component: _ee5286ee,
    props: {"name":"ConfirmReservation"},
    name: "ConfirmReservation___pl"
  }, {
    path: "/pl/enroll/:type/:id",
    component: _0cbc9a7a,
    props: {},
    name: "Enroll1___pl"
  }, {
    path: "/pl/register/:type?/:courseId?",
    component: _2314fd00,
    props: {},
    name: "Register___pl"
  }, {
    path: "/en/enroll-individual/:type/:sId/:id",
    component: _cec99988,
    props: {},
    name: "Enroll2___en"
  }, {
    path: "/pl/enroll-individual/:type/:sId/:id",
    component: _cec99988,
    props: {},
    name: "Enroll2___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
